import "babel-polyfill"
import "isomorphic-fetch"
import React from "react"
import { connect } from "react-redux"

// import AppNav from '../components/app/appNav'
// import Amplify, { API, Auth } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../../utils/config"
import { Helmet } from "react-helmet"
import parse from "url-parse"

import LoadingSpinner from "../loading"

import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

import {
  getVideosToModerate,
  moderationSubmit,
} from "../../actions/updateModeration"

// eslint-disable-next-line
import "../../css/app.css"

Amplify.configure(amplifyconfig)

const mapStateToProps = ({ moderatevideos, isLoading, isLoadingModerate }) => {
  return { moderatevideos, isLoading, isLoadingModerate }
}

const mapDispatchToProps = dispatch => ({
  getVideosToModerate: templateid => dispatch(getVideosToModerate(templateid)),
  moderationSubmit: (mode, id) => dispatch(moderationSubmit(mode, id)),
})

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const url = parse(window.location.href, true)
    const templateid = url.query.t
    this.props.getVideosToModerate(templateid)
  }

  renderPreviewVideo(sessionid, i) {
    const { isLoadingModerate } = this.props

    const previewVideo = (
      <div class="d-flex align-items-center justify-content-center">
        <video
          key={i}
          class="video-preview"
          // autoPlay="autoplay"
          // muted
          playsInline
          controls={true}
          loop={true}
        >
          <source
            src={`https://storylavamedia.s3.amazonaws.com/session/${sessionid}/video/video.mp4
`}
          />
        </video>
        <div class="d-flex flex-column">
          {isLoadingModerate && isLoadingModerate[sessionid] ? (
            <div>
              <LoadingSpinner class="small light" />
            </div>
          ) : null}

          <div class="m-2 d-flex justify-content-center moderation-button moderation-approve">
            <button
              onClick={() =>
                this.props.moderationSubmit("modapprove", sessionid)
              }
            >
              <FaCheck size={30} color="#e6e6e6" />
            </button>
          </div>
          <div class="m-2 d-flex justify-content-center moderation-button moderation-reject">
            <button
              onClick={() =>
                this.propslmoderationSubmit("modreject", sessionid)
              }
            >
              <FaTimes size={30} color="#e6e6e6" />
            </button>
          </div>
        </div>
      </div>
    )

    return previewVideo
  }

  renderVideoRows() {
    const { moderatevideos, isLoading } = this.props
    let items = isLoading ? (
      <div class="p-4">
        <LoadingSpinner />
      </div>
    ) : moderatevideos && moderatevideos.length ? (
      moderatevideos.map((item, i) => (
        <div class={`row row-moderate`} key={`moderate-${i}`}>
          <div class="col-md-12 d-flex flex-row flex-wrap align-items-center justify-content-center moderation-video-row">
            {this.renderPreviewVideo(item.id, i)}
          </div>
        </div>
      ))
    ) : (
      <div class="row">
        <div class="col-md-12 d-flex flex-row align-items-center justify-content-center moderation-video-row">
          <h4>Nada</h4>
        </div>
      </div>
    )
    return items
  }

  render() {
    const url = parse(window.location.href, true)
    const templateid = url.query.t
    console.log("[[templateid]]")
    console.log(templateid)

    return (
      <div>
        <Helmet>
          <body className="app-dark" />
        </Helmet>
        {/* <AppNav logo={this.state.company && this.state.company.companylogo ? this.state.company.companylogo : ''} /> */}

        <div class="container container-app container-moderation">
          {/* <div class="row moderation-action d-flex align-items-center justify-content-center">
            <div class="col-md-5 mx-2 d-flex justify-content-center moderation-button moderation-reject">
              <button onClick={() => this.moderationSubmit("modreject")}>
                <FaTimes size={30} color="#e6e6e6" />
              </button>
            </div>
            <div class="col-md-5 mx-2 d-flex justify-content-center moderation-button moderation-approve">
              <button onClick={() => this.moderationSubmit("modapprove")}>
                <FaCheck size={30} color="#e6e6e6" />
              </button>
            </div>
          </div> */}

          {this.renderVideoRows()}

          <div class="row">
            <div class="col-md-12 d-flex flex-row align-items-center justify-content-center moderation-refresh">
              <button
                onClick={() => {
                  this.props.getVideosToModerate(templateid)
                }}
              >
                <MdRefresh size={40} color="#666666" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export default App
export default connect(mapStateToProps, mapDispatchToProps)(App)
