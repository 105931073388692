// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"

import moment from "moment"

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts"

const CustomSymbol = ({ size, color, borderWidth, borderColor, cx, cy }) => (
  <g>
    <circle
      cx={cx}
      cy={cy}
      fill={borderColor}
      r={4}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    {/* <circle
      cx={cx}
      cy={cy}
      r={8}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    /> */}
  </g>
)

const CustomDataPointLabel = props => {
  const { x, y, width, height, value } = props
  const radius = 10

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#333"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="0.7em"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  )
}
const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="chart-tooltip">
        <div className="chart-tooltip-header">{`${moment(
          parseInt(payload[0].payload.time)
        ).format("MMM D")}`}</div>
        <div className="chart-tooltip-data">{payload[0].payload.value}</div>
      </div>
    )
  }

  return null
}

const CustomXAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        transform="rotate(-90)"
        fontSize="0.8em"
      >
        {moment(payload.value).format("MMM D")}
      </text>
    </g>
  )
}
const CustomYAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        fontSize="0.8em"
        fontWeight="600"
      >
        {payload.value}
      </text>
    </g>
  )
}
const CustomYAxisLabel = props => {
  const { x, y, width, height, value } = props
  const radius = 10

  console.log("x")
  console.log(x)
  console.log("y")
  console.log(y)
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#666"
        textAnchor="start"
        dominantBaseline="end"
        fontSize="0.7em"
        transform="rotate(-90)"
      >
        {value}
      </text>
    </g>
  )
}

const TimeChart = ({ data, ticks, axisLabels }) => (
  <ResponsiveContainer width="95%" height={300}>
    <ScatterChart margin={{ top: 40, bottom: 40, left: 10, right: 10 }}>
      >
      <XAxis
        dataKey="time"
        domain={["auto", "auto"]}
        name="Time"
        // tickFormatter={unixTime => moment(unixTime).format("MMM D")}
        ticks={ticks}
        type="number"
        interval={0}
        tick={<CustomXAxisTick />}
        // unit={1}
      />
      <YAxis
        dataKey="value"
        name="Value"
        // label={{ value: "My Y Axis", angle: -90, position: "insideLeft" }}
        label={
          <CustomYAxisLabel
            value={axisLabels && axisLabels.y ? axisLabels.y : ""}
            x={-90}
            y={40}
          />
        }
        tick={<CustomYAxisTick />}
        allowDecimals={false}
      />
      <Tooltip content={<CustomTooltip />} />
      <Scatter
        data={data}
        line={{ stroke: "#eee" }}
        lineJointType="monotoneX"
        lineType="joint"
        shape={
          <CustomSymbol
            size={5}
            color="#b2d6ef"
            borderWidth={1}
            borderColor="#307adb"
          />
        }
        isAnimationActive={true}
        name="Values"
      >
        <LabelList
          dataKey="value"
          position="top"
          content={CustomDataPointLabel}
        />
      </Scatter>
    </ScatterChart>
  </ResponsiveContainer>
)

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <TimeChart
        data={this.props.data}
        ticks={this.props.ticks}
        axisLabels={this.props.axisLabels}
      />
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }
export default Snippet
