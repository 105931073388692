// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import moment from "moment"

import {
  MdFileDownload,
  MdPlayCircleOutline,
  MdStarBorder,
  MdStar,
} from "react-icons/md"

import { connect } from "react-redux"
import { getVideoCollection } from "../../actions/getVideoCollection"
import { updateVideoTags, updateState } from "../../actions/updateVideoTags"
import ChartLineScatter from "./chartLineScatter"
import LabelTotal from "./labelTotal"
import Loading from "../loading"

import ModalVideo from "../../components/modal/viewVideo"

import parse from "url-parse"
import Helmet from "react-helmet"

// PRODUCTION

const mapStateToProps = ({
  reviews,
  campaign,
  isLoading,
  isLoadingMini,
  filterStar,
  filterSort,
}) => {
  console.log("mapStateToProps reviews ###")
  console.log(reviews)
  return { reviews, campaign, isLoading, isLoadingMini, filterStar, filterSort }
}

const mapDispatchToProps = dispatch => ({
  getVideoCollection: templateid => dispatch(getVideoCollection(templateid)),
  updateState: (prop, val) => dispatch(updateState(prop, val)),
  updateVideoTags: (sessionid, tags, mode) =>
    dispatch(updateVideoTags(sessionid, tags, mode)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.getTagsFromReview = this.getTagsFromReview.bind(this)

    this.state = {
      scrolled: "",
      reviewLength: 20,
      currentPage: 0,
      videoSrc: "",
      sortOrder: "asc",
      localTags: {},
    }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.setState({ templateid: url.query.t })
    if (
      url.query &&
      url.query.t &&
      (!this.props.reviews ||
        (this.props.reviews && !this.props.reviews[url.query.t]))
    ) {
      console.log("calling videocollection API** users")
      this.props.getVideoCollection(url.query.t)
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }
  addDefaultSrc(ev) {
    ev.target.src =
      "https://storylavaprime.s3.amazonaws.com/static/default/blank1.jpg"
  }
  loadAndPlayMovie(sessionid) {
    this.setState(
      {
        videoSrc: `https://storylavaprime.s3.amazonaws.com/session/${sessionid}/video/videostory.mp4`,
      },
      () => {
        this.playVideoInModal()
      }
    )
  }

  getTagsFromReview(targetSessionId, submittedTags) {
    const { reviews } = this.props
    const currentReview = reviews[this.state.templateid].find(
      ({ sessionid }) => sessionid === targetSessionId
    )
    let currentTags =
      currentReview && currentReview.tags ? currentReview.tags : []

    if (currentTags.length > 0) {
      currentTags = JSON.parse(currentTags)
      currentTags = currentTags.some(subitem => submittedTags.includes(subitem))
    } else {
      currentTags = false
    }
    console.log("currentTags -boolean")
    console.log(currentTags)

    return currentTags
  }

  reviewsList(start) {
    const { reviews, isLoadingMini, filterSort, filterStar } = this.props
    let sortA
    let sortB
    if (filterSort === "desc") {
      sortA = 1
      sortB = -1
    } else if (filterSort === "asc") {
      sortA = -1
      sortB = 1
    }

    console.log("((((((((((reviews))))))))))")
    console.log(reviews)

    if (this.state.templateid && reviews && reviews[this.state.templateid]) {
      const reviewList = this.props.reviews[this.state.templateid]
        .filter(item =>
          filterStar ? item.tags && item.tags.includes("star") : item
        ) //filter for stars
        .sort((a, b) => (a.datecreated > b.datecreated ? sortA : sortB))
        .slice((start + 1) * 20 - 20, (start + 1) * 20 - 0) //get 20 elements, based on page number. Note that .slice doesnt include the end value, so have to increment that by one
        .map((item, i) =>
          item.sessionid ? (
            <tr key={`reviews-id-${i}`}>
              <td>
                <div class="row d-flex flex-row align-items-center ">
                  <div class="col-md-2">
                    <button
                      class="d-flex flex-column align-items-center justify-content-center video-modal"
                      style={{ border: 0, padding: 0, background: "none" }}
                      type="button"
                      data-toggle="modal"
                      data-target="#videoModal"
                      onClick={() => {
                        this.loadAndPlayMovie(item.sessionid)
                      }}
                    >
                      <div
                        style={{
                          width: 80,
                          height: 80,
                          backgroundColor: "rgba(0,0,0,0.2",
                          position: "absolute",
                          zIndex: 9,
                        }}
                      ></div>
                      <MdPlayCircleOutline
                        style={{
                          color: "rgba(255,255,255,0.55)",
                          position: "absolute",
                          zIndex: 10,
                          width: 45,
                          height: 45,
                          margin: "auto",
                        }}
                      />

                      <img
                        onError={this.addDefaultSrc}
                        src={`https://storylavaprime.s3.amazonaws.com/session/${item.sessionid}/video/poster.jpg`}
                        width="80"
                        height="80"
                      />
                    </button>
                  </div>
                  <div class="px-3 col-md-3">
                    {/* {item.datecreated} */}
                    {moment
                      .unix(item.datecreated)
                      .format("MMM. D, YYYY - h:mm a")}
                  </div>
                  <div class="px-3 col-md-5">{item.email}</div>
                  <div class="px-3 col-md-2" style={{ marginLeft: "auto" }}>
                    <div class="d-flex flex-row">
                      <button
                        class="icon-tags"
                        onClick={() => {
                          let mode
                          let submittedTags = ["star"]
                          // let currentTags = this.getTagsFromReview(item.sessionid)
                          // console.log("[[[currentTags]]]")
                          // console.log(currentTags)

                          if (
                            this.getTagsFromReview(
                              item.sessionid,
                              submittedTags
                            ) === true
                          ) {
                            mode = "delete"

                            // this.setState({
                            //   localTags: {
                            //     ...this.state.localTags,
                            //     [item.sessionid]: submittedTags.filter(
                            //       item => !submittedTags.includes(item)
                            //     ),
                            //   },
                            // })
                          } else {
                            mode = "add"
                            console.log("***adding")

                            // this.setState({
                            //   localTags: {
                            //     ...this.state.localTags,
                            //     [item.sessionid]: submittedTags,
                            //   },
                            // })
                          }

                          this.props.updateVideoTags(
                            item.sessionid,
                            submittedTags,
                            mode
                          )
                        }}
                      >
                        <div class="d-flex flex-column">
                          {this.getTagsFromReview(item.sessionid, ["star"]) ===
                          true ? (
                            <MdStar size={26} className="blue-1" />
                          ) : (
                            <MdStarBorder size={26} className="blue-1" />
                          )}
                          {isLoadingMini && isLoadingMini[item.sessionid] ? (
                            <div
                              class="d-flex justify-content-center align-items-center "
                              style={{ height: 4 }}
                            >
                              <Loading size="mini" padding={2} />
                            </div>
                          ) : (
                            <div style={{ height: 4 }}></div>
                          )}
                        </div>
                      </button>

                      <a
                        href={`https://storylavaprime.s3.amazonaws.com/session/${item.sessionid}/video/videostory.mp4`}
                        // download={`video-${item.sessionid}.mp4`}
                        target="_blank"
                      >
                        <MdFileDownload size={26} className="grayblue-1" />
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ) : null
        )

      if (reviewList.length > 0) {
        return reviewList
      } else {
        return (
          <tr>
            <td>No reviews</td>
          </tr>
        )
      }
    } else {
      return []
    }
  }

  render() {
    const { reviews, campaign, isLoading, filterSort, filterStar } = this.props
    console.log("this.state")
    console.log(this.state)

    const campaignDetail = campaign
      ? campaign.filter(item => item.id === this.state.templateid)
      : []

    // console.log("campaignDetail - users")
    // console.log(campaignDetail)
    let paginationLength =
      this.state.templateid && reviews && reviews[this.state.templateid]
        ? reviews[this.state.templateid].length / 20
        : 1

    console.log("paginationLength - before round")
    console.log(paginationLength)
    paginationLength = Math.ceil(paginationLength)

    const pagination = currentPage => {
      const paginationHolder = []
      for (let i = 0; i < paginationLength; i++) {
        console.log("pushing pagination")
        paginationHolder.push(
          <li class={`page-item ${currentPage === i ? "active" : ""}`}>
            <button
              class="page-link"
              onClick={() => {
                this.setState({ currentPage: i })
              }}
            >
              {i + 1}
            </button>
          </li>
        )
      }
      return paginationHolder
    }

    // const pagination = paginationLength => {
    //   let pageNumbers = []

    //   console.log("paginationLengtha")
    //   console.log(paginationLength)
    //   for (let i = 1; i < paginationLength; i++) {
    //     //Create the parent and add the children
    //     pageNumbers.push(
    //       <li class="page-item">
    //         <button class="page-link">{i}</button>
    //       </li>
    //     )
    //   }
    //   return pageNumbers
    // }

    console.log("this.reviewsList")
    console.log(this.reviewsList(0))

    console.log("pagination")
    console.log(pagination)

    return (
      <div class="reporting-container">
        <Helmet
          script={[
            {
              type: "text/javascript",
              innerHTML: `$('#videoModal').on('hidden.bs.modal', function (e) {
                $('#videoModal video').get(0).pause()
                })`,
            },
          ]}
        />
        {isLoading ? <Loading /> : null}
        {!isLoading ? (
          <div>
            <div class="reporting-header">
              <div class="form-group header-sort d-flex flex-row justify-content-end">
                <div>
                  <button
                    class="icon-tags"
                    onClick={event => {
                      this.props.updateState("filterStar", !filterStar)
                    }}
                  >
                    {filterStar === true ? (
                      <MdStar size={26} className="blue-1" />
                    ) : (
                      <MdStarBorder size={26} className="blue-1" />
                    )}
                  </button>
                </div>
                <select
                  class="form-control col-sm-2"
                  onChange={event => {
                    this.props.updateState("filterSort", event.target.value)
                    // this.setState({
                    //   sortOrder: event.target.value,
                    // })
                  }}
                >
                  <option
                    value="asc"
                    selected={filterSort === "asc" ? "selected" : null}
                  >
                    Newest first
                  </option>
                  <option
                    value="desc"
                    selected={filterSort === "desc" ? "selected" : null}
                  >
                    Oldest first
                  </option>
                </select>
              </div>
            </div>
            <table class="table table-striped table-bordered mt-2">
              <tbody>{this.reviewsList(this.state.currentPage)}</tbody>
            </table>
            {paginationLength > 1 ? (
              <nav aria-label="Page navigation">
                <ul class="pagination flex-wrap">
                  {this.state.currentPage !== 0 ? (
                    <li class="page-item">
                      <button
                        class="page-link"
                        onClick={() => {
                          this.setState({
                            currentPage: this.state.currentPage - 1,
                          })
                        }}
                      >
                        Previous
                      </button>
                    </li>
                  ) : null}

                  {pagination(this.state.currentPage)}
                  {this.state.currentPage !== paginationLength - 1 ? (
                    <li class="page-item">
                      <button
                        class="page-link"
                        onClick={() => {
                          this.setState({
                            currentPage: this.state.currentPage + 1,
                          })
                        }}
                      >
                        Next
                      </button>
                    </li>
                  ) : null}
                </ul>
              </nav>
            ) : //end paginationLength > 1
            null}
          </div>
        ) : null}
        <ModalVideo
          src={this.state.videoSrc}
          setClickPlay={click => (this.playVideoInModal = click)}
        />
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
