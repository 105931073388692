// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"

export function getVideosToModerate(templateid) {
  let apiName = "Storylava"
  let path = "moderatevideos"

  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get videocollection function---")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            t: templateid,
          },
        }

        //begin promise
        new Promise(resolve => {
          API.get(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("((getVideosToModerate - data))")
              console.log(data)

              return resolve(
                dispatch({
                  type: "VIDEOS_TOMODERATE_REPLACE",
                  data: data,
                })
              )
            })
            .catch(error => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
export function moderationSubmit(mode, id) {
  let apiName = "Storylavaweb"
  let path = "admin/moderate"

  return dispatch => {
    dispatch({
      type: "LOADING_MODERATESUBMIT_START",
      data: { sessionid: id },
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get videocollection function---")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          body: {
            sessionid: id,
            mode: mode,
          },
        }

        //begin promise
        new Promise(resolve => {
          API.post(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("((moderationSubmit - data))")
              console.log(data)

              return resolve(
                dispatch({
                  type: "VIDEOS_AFTERMODERATE_REPLACE",
                  data: { sessionid: id },
                })
              )
            })
            .catch(error => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
