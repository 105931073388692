// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"

import moment from "moment"

import * as FontAwesome from "react-icons/fa"
import * as MaterialIcons from "react-icons/md"
// import {
//   FaFacebookSquare,
//   FaTwitter,
//   FaInstagram,
//   FaCommentDots,
//   FaDesktop,
// } from "react-icons/fa"
import { MdMailOutline, MdOndemandVideo } from "react-icons/md"

import {
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
  Label,
} from "recharts"

const CustomSymbol = ({ size, color, borderWidth, borderColor, cx, cy }) => (
  <g>
    <circle
      cx={cx}
      cy={cy}
      fill="#fff"
      r={4}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      cx={cx}
      cy={cy}
      r={8}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
)

class CustomDataPointLabel extends React.Component {
  render() {
    const { x, y, width, height, value, color } = this.props

    if (value > 0) {
      return (
        <g style={{ zIndex: 10, borderWidth: 1 }}>
          <text
            x={x + width + 5}
            y={y + 7}
            // x={x + width / 2 + 15}
            // y={y - radius + 15}
            fill={color ? color : "#333"}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize="0.7em"
            fontWeight="600"
          >
            {value}
          </text>
        </g>
      )
    } else {
      return null
    }
  }
}

// const CustomDataPointLabel = props => {
//   const { x, y, width, height, value, color } = props
//   const radius = 10
//   if (value > 0) {
//     return (
//       <g style={{ zIndex: 10, borderWidth: 1 }}>
//         <text
//           x={x + width + 5}
//           y={y + 7}
//           // x={x + width / 2 + 15}
//           // y={y - radius + 15}
//           fill={color ? color : "#333"}
//           textAnchor="middle"
//           dominantBaseline="middle"
//           fontSize="0.7em"
//           fontWeight="600"
//         >
//           {value}
//         </text>
//       </g>
//     )
//   } else {
//     return
//   }
// }

const CustomTooltip = ({
  active,
  payload,
  label,
  aggregateOption,
  shareOption,
  barOptionReverse,
}) => {
  // console.log("tooltip - label")
  // console.log(label)
  // console.log("tooltip - payload")
  // console.log(payload)
  // console.log("tooltip - shareOption")
  // console.log(shareOption)
  if (active) {
    return payload && payload[0] ? (
      <div className="chart-tooltip">
        <div className="chart-tooltip-header">{`${moment(
          parseInt(payload[0].payload.time)
        ).format("MMM D")}`}</div>
        <div className="chart-tooltip-data">
          {barOptionReverse.map((item, i) => {
            let icon
            if (item.icon.startsWith("Fa")) {
              icon = React.createElement(FontAwesome[item.icon], {
                // className: "chart-social-icon-tooltip",
                size: 20,
                color: item.color,
              })
            } else if (item.icon.startsWith("Md")) {
              icon = React.createElement(MaterialIcons[item.icon], {
                // className: "chart-social-icon-tooltip",
                size: 20,
                color: item.color,
              })
            } else if (item.icon.startsWith("Custom")) {
              icon = React.createElement("div", {
                className: "sharer-tk",
              })
            }
            return payload[0].payload[item.name] && shareOption[item.name] ? (
              <div>
                {icon}
                {payload[0].payload[item.name]}
              </div>
            ) : null
          })}
        </div>
      </div>
    ) : null
  }

  return null
}

const CustomXAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        transform="rotate(-90)"
        fontSize="0.8em"
      >
        {moment(payload.value).format("MMM D")}
      </text>
    </g>
  )
}
const CustomYAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        fontSize="0.8em"
        fontWeight="600"
      >
        {payload.value}
      </text>
    </g>
  )
}
const CustomYAxisLabel = props => {
  const { x, y, width, height, value } = props
  const radius = 10

  // console.log("x")
  // console.log(x)
  // console.log("y")
  // console.log(y)
  return (
    <g>
      <text
        x={x}
        y={y}
        fill="#666"
        textAnchor="start"
        dominantBaseline="end"
        fontSize="0.7em"
        transform="rotate(-90)"
      >
        {value}
      </text>
    </g>
  )
}
// class FooBar extends React.Component {
//   render() {
//     const { key, color } = this.props
//     console.log("*****bar - key")
//     console.log(key)
//     // return (
//     //   <Bar
//     //     dataKey={key}
//     //     stackId="a"
//     //     fill={color}
//     //     maxBarSize={20}
//     //     isAnimationActive={false}
//     //   >
//     //     <LabelList
//     //       dataKey={key}
//     //       content={<CustomDataPointLabel color={color} />}
//     //     />
//     //   </Bar>
//     // )
//   }
// }
// const BarComponent = props => {
//   const { key, color } = props
//   console.log("*****bar - key")
//   console.log(key)

//   return (
//     <Bar
//       dataKey={key}
//       stackId="a"
//       fill={color}
//       maxBarSize={20}
//       isAnimationActive={false}
//     >
//       <LabelList
//         dataKey={key}
//         content={<CustomDataPointLabel color={color} />}
//       />
//     </Bar>
//   )
// }

const TimeChart = ({
  barOption,
  barOptionReverse,
  data,
  ticks,
  axisLabels,
  aggregateOption,
  shareOption,
}) => (
  <ResponsiveContainer width="95%" height={300}>
    <BarChart data={data} margin={{ top: 40, bottom: 40, left: 10, right: 10 }}>
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="time"
        domain={["auto", "auto"]}
        name="Time"
        // // tickFormatter={unixTime => moment(unixTime).format("MMM D")}
        ticks={ticks}
        type="number"
        interval={0}
        tick={<CustomXAxisTick />}
        // unit={1}
      />
      <YAxis
        dataKey="total"
        name="Value"
        // label={{ value: "My Y Axis", angle: -90, position: "insideLeft" }}
        label={
          <CustomYAxisLabel
            value={axisLabels && axisLabels.y ? axisLabels.y : ""}
            x={-90}
            y={40}
          />
        }
        tick={<CustomYAxisTick />}
        allowDecimals={false}
      />
      {/* <XAxis dataKey="name" /> */}
      {/* <YAxis /> */}
      <Tooltip
        content={
          <CustomTooltip
            aggregateOption={aggregateOption}
            shareOption={shareOption}
            barOptionReverse={barOptionReverse}
          />
        }
        cursor={{ fill: "rgba(0,0,0,0.04)" }}
      />
      {/* === TOTALS === */}
      {barOption.map((item, i) =>
        shareOption[item.name] === true &&
        aggregateOption.email === true &&
        aggregateOption.landing === true ? (
          <Bar
            dataKey={item.name}
            key={i}
            stackId="a"
            fill={item.color}
            maxBarSize={20}
            isAnimationActive={false}
            barSize={5}
          >
            <LabelList
              dataKey={item.name}
              content={<CustomDataPointLabel color={item.color} />}
            />
          </Bar>
        ) : null
      )}
      {/* === EMAIL ONLY === */}
      {barOption.map((item, i) =>
        shareOption[item.name] === true &&
        aggregateOption.email === true &&
        aggregateOption.landing === false ? (
          <Bar
            dataKey={`email[${item.name}]`}
            key={i}
            stackId="a"
            fill={item.color}
            maxBarSize={20}
            isAnimationActive={false}
            barSize={5}
          >
            <LabelList
              dataKey={`email[${item.name}]`}
              content={<CustomDataPointLabel color={item.color} />}
            />
          </Bar>
        ) : null
      )}
      {/* === LANDING ONLY === */}
      {barOption.map((item, i) =>
        shareOption[item.name] === true &&
        aggregateOption.email === false &&
        aggregateOption.landing === true ? (
          <Bar
            dataKey={`landing[${item.name}]`}
            key={i}
            stackId="a"
            fill={item.color}
            maxBarSize={20}
            isAnimationActive={false}
            barSize={5}
          >
            <LabelList
              dataKey={`landing[${item.name}]`}
              content={<CustomDataPointLabel color={item.color} />}
            />
          </Bar>
        ) : null
      )}
      {/* END OPTIONS */}
    </BarChart>
  </ResponsiveContainer>
)

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      share: {
        facebook: true,
        twitter: true,
        instagram: true,
        sms: true,
        web: true,
        tiktok: true,
        snapchat: true,
        download: true,
      },
      aggregate: {
        email: true,
        landing: true,
      },
    }
  }

  //From https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
  changeOption(option, suboption, value) {
    this.setState(prevState => ({
      [option]: {
        ...prevState[option],
        [suboption]: value,
      },
    }))

    // this.setState(prevState => ({
    //   [option]: {
    //     ...prevState[option],
    //     [suboption]: {
    //       ...prevState[option][suboption],
    //       [suboption]: value,
    //     },
    //   },
    // }))
  }
  socialIconToolbar(barOption) {
    return (
      <div className="chart-toolbar-social d-flex flex-column justify-content-center align-items-start">
        <div>
          <div className="chart-toolbar-social-title">Click source</div>
          <div className="chart-toolbar-aggregate d-flex flex-row justify-content-center align-items-center">
            <button
              type="button"
              onClick={() => {
                this.changeOption(
                  "aggregate",
                  "email",
                  !this.state.aggregate.email
                )
              }}
              class={`btn btn-toolbar-social ${
                this.state.aggregate.email ? "active" : ""
              } d-flex flex-row justify-content-center align-items-center`}
            >
              <MdMailOutline
                className="chart-social-icon-tooltip grayblue-1"
                size={22}
              />
            </button>
            <button
              type="button"
              onClick={() => {
                this.changeOption(
                  "aggregate",
                  "landing",
                  !this.state.aggregate.landing
                )
              }}
              class={`btn btn-toolbar-social ${
                this.state.aggregate.landing ? "active" : ""
              } d-flex flex-row justify-content-center align-items-center`}
            >
              <MdOndemandVideo
                className="chart-social-icon-tooltip blue-1"
                size={22}
              />{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="chart-toolbar-social-title">Intent</div>
          <div className="chart-toolbar-share d-flex flex-row flex-wrap justify-content-start align-items-center">
            {barOption.map((item, i) => {
              let icon
              if (item.icon.startsWith("Fa")) {
                icon = React.createElement(FontAwesome[item.icon], {
                  className: "chart-social-icon-tooltip",
                  size: 20,
                  color: item.color,
                })
              } else if (item.icon.startsWith("Md")) {
                icon = React.createElement(MaterialIcons[item.icon], {
                  className: "chart-social-icon-tooltip",
                  size: 20,
                  color: item.color,
                })
              } else if (item.icon.startsWith("Custom")) {
                icon = React.createElement("div", {
                  className: "sharer-tk chart-social-icon-tooltip",
                })
              }
              // console.log("[[[[[item.icon]]]]]")
              // console.log(icon)
              return (
                <button
                  type="button"
                  onClick={() => {
                    this.changeOption(
                      "share",
                      item.name,
                      !this.state.share[item.name]
                    )
                  }}
                  class={`btn btn-toolbar-social ${
                    this.state.share[item.name] ? "active" : ""
                  } d-flex flex-row justify-content-center align-items-center`}
                >
                  {icon}
                  {/* {this.state.share[item.name] ? (
                <FontAwesome.FaCheck
                  size={12}
                  color="rgba(0,0,0,0.6)"
                  style={{ marginTop: 10, marginLeft: -2 }}
                />
              ) : null} */}
                </button>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    console.log("barchart - this.props.data")
    console.log(this.props.data)
    console.log("barchart - this state")
    console.log(this.state)

    const barOption = [
      {
        name: "facebook",
        color: "#1875d3",
        icon: "FaFacebookSquare",
      },
      {
        name: "twitter",
        color: "#07b2d9",
        icon: "FaTwitter",
      },
      {
        name: "instagram",
        color: "#f27405",
        icon: "FaInstagram",
      },
      {
        name: "snapchat",
        color: "#f2ce16",
        icon: "FaSnapchatGhost",
      },
      {
        name: "tiktok",
        color: "#b00be5",
        icon: "CustomTiktok",
      },
      {
        name: "sms",
        color: "#03a696",
        icon: "FaCommentDots",
      },
      {
        name: "web",
        color: "#025159",
        icon: "FaDesktop",
      },

      {
        name: "download",
        color: "#f22e62",
        icon: "MdFileDownload",
      },
    ]

    const barOptionReverse = [...barOption].reverse()

    return (
      <div class="w-100">
        <div class="chart-options">
          {this.socialIconToolbar(barOption)}
          {/* <button
            type="button"
            onClick={() => {
              this.changeOption("social")
            }}
            class={`btn ${
              this.state.shareOption === "social" ? "btn-primary" : "btn-cta"
            }`}
          >
            Social totals
          </button>
          <button
            type="button"
            onClick={() => {
              this.changeOption("channel")
            }}
            class={`btn ${
              this.state.shareOption === "channel" ? "btn-primary" : "btn-cta"
            }`}
          >
            By channel
          </button> */}
        </div>
        <TimeChart
          barOption={barOption}
          barOptionReverse={barOptionReverse}
          aggregateOption={this.state.aggregate}
          shareOption={this.state.share}
          data={this.props.data}
          ticks={this.props.ticks}
          axisLabels={this.props.axisLabels}
        />
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }
export default Snippet
