// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { connect } from "react-redux"
import { getSocialClicks } from "../../actions/getSocialClicks"
import ChartBarStacked from "./chartBarStacked"
import LabelTotal from "./labelTotal"
import Loading from "../loading"

import parse from "url-parse"

const mapStateToProps = ({ social, campaign, isLoading }) => {
  return { social, campaign, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getSocialClicks: templateid => dispatch(getSocialClicks(templateid)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.setState({ templateid: url.query.t })
    if (
      url.query &&
      url.query.t &&
      (!this.props.social ||
        (this.props.social && !this.props.social[url.query.t]))
    ) {
      console.log("calling getSocialClicks API *** social")
      this.props.getSocialClicks(url.query.t)
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  render() {
    const { social, campaign, isLoading } = this.props
    console.log("social")
    console.log(social)
    console.log("this.state")
    console.log(this.state)
    console.log("campaign - social - props")
    console.log(campaign)

    const chartData =
      this.props.social &&
      this.state.templateid &&
      this.props.social[this.state.templateid]
        ? this.props.social[this.state.templateid].data
        : []
    const chartLabels =
      this.props.social &&
      this.state.templateid &&
      this.props.social[this.state.templateid]
        ? this.props.social[this.state.templateid].labels
        : []
    const socialTotal =
      this.props.social &&
      this.state.templateid &&
      this.props.social[this.state.templateid]
        ? this.props.social[this.state.templateid].total
        : []
    const socialBounds =
      this.props.social &&
      this.state.templateid &&
      this.props.social[this.state.templateid]
        ? this.props.social[this.state.templateid].bounds
        : []

    console.log("chartData -- social")
    console.log(chartData)
    console.log("socialBounds")
    console.log(socialBounds)
    return (
      <div class="reporting-container">
        {isLoading ? <Loading /> : null}
        {!isLoading && this.props.social && this.state.templateid ? (
          <div>
            <LabelTotal
              data={socialTotal}
              text="share clicks"
              singularText="share click"
              bounds={socialBounds}
            />
            <div class="d-flex flex-row align-items-center justify-content-start">
              {chartData.length > 0 ? (
                <ChartBarStacked
                  ticks={chartLabels}
                  data={chartData}
                  axisLabels={{ y: "# of share clicks" }}
                />
              ) : (
                <div>No data to display</div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snippet)
