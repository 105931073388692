// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { connect } from "react-redux"
import { getVideoCollection } from "../../actions/getVideoCollection"
import ChartLineScatter from "./chartLineScatter"
import LabelTotal from "./labelTotal"
import Loading from "../loading"

import parse from "url-parse"

const mapStateToProps = ({ videosgrouped, campaign, isLoading }) => {
  return { videosgrouped, campaign, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getVideoCollection: templateid => dispatch(getVideoCollection(templateid)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.setState({ templateid: url.query.t })
    if (
      url.query &&
      url.query.t &&
      (!this.props.videosgrouped ||
        (this.props.videosgrouped && !this.props.videosgrouped[url.query.t]))
    ) {
      console.log("calling videocollection API *** videocreation")
      this.props.getVideoCollection(url.query.t)
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  render() {
    const { videosgrouped, campaign, isLoading } = this.props
    console.log("videosgrouped")
    console.log(videosgrouped)
    console.log("this.state")
    console.log(this.state)
    console.log("campaign - creation - props")
    console.log(campaign)

    const chartData =
      this.props.videosgrouped &&
      this.state.templateid &&
      this.props.videosgrouped[this.state.templateid]
        ? this.props.videosgrouped[this.state.templateid].data
        : []
    const chartLabels =
      this.props.videosgrouped &&
      this.state.templateid &&
      this.props.videosgrouped[this.state.templateid]
        ? this.props.videosgrouped[this.state.templateid].labels
        : []
    const videoTotal =
      this.props.videosgrouped &&
      this.state.templateid &&
      this.props.videosgrouped[this.state.templateid]
        ? this.props.videosgrouped[this.state.templateid].total
        : []
    const videoBounds =
      this.props.videosgrouped &&
      this.state.templateid &&
      this.props.videosgrouped[this.state.templateid]
        ? this.props.videosgrouped[this.state.templateid].bounds
        : []

    return (
      <div class="reporting-container">
        {isLoading ? <Loading /> : null}
        {!isLoading && this.props.videosgrouped && this.state.templateid ? (
          <div>
            <LabelTotal
              data={videoTotal}
              text="videos created"
              singularText="video created"
              bounds={videoBounds}
            />
            <div class="d-flex flex-row align-items-center justify-content-start mt-2">
              {chartData.length > 0 ? (
                <ChartLineScatter
                  data={chartData}
                  axisLabels={{ y: "# of videos" }}
                  ticks={chartLabels}
                />
              ) : (
                <div>No data to display</div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snippet)
