import "babel-polyfill"
import "isomorphic-fetch"
import React from "react"
import { navigate } from "@reach/router"

// import Amplify, { Auth, Cache } from "aws-amplify"
import Amplify from '@aws-amplify/core';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';


import { amplifyconfig } from "../utils/config"

Amplify.configure(amplifyconfig)

class PrivateRoute extends React.Component {
  componentDidMount() {
    let cid = Cache.getItem("cid")
    console.log("cache -cid")
    console.log(cid)
    if (!cid) {
      console.log("***signing out")
      Cache.clear()
      Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err))
    }
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        console.log("LOGGED IN2****")
        console.log(user)
      })
      .catch(err => {
        console.log("ERRORED ///****")
        console.log(err)
        navigate(`/`)
      })
  }
  render() {
    const { component: Component, location, ...rest } = this.props
    // console.log('((((((isLoggedIn()))))))')
    // console.log(isLoggedIn())
    // if (isLoggedIn() === false) {
    //   navigate(`/app`)
    //   return null
    // }

    return (
      <div>
        <Component {...rest} />
      </div>
    )
  }
}

export default PrivateRoute
// export default withAuthenticator(PrivateRoute, false)
