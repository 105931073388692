// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { connect } from "react-redux"
import { getVideoCollection } from "../../actions/getVideoCollection"
import ChartLineScatter from "./chartLineScatter"
import LabelTotal from "./labelTotal"
import Loading from "../loading"

import parse from "url-parse"

const mapStateToProps = ({ users, campaign, isLoading }) => {
  return { users, campaign, isLoading }
}

const mapDispatchToProps = dispatch => ({
  getVideoCollection: templateid => dispatch(getVideoCollection(templateid)),
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: "",
    }
  }
  componentDidMount() {
    const url = parse(window.location.href, true)
    this.setState({ templateid: url.query.t })
    if (
      url.query &&
      url.query.t &&
      (!this.props.users ||
        (this.props.users && !this.props.users[url.query.t]))
    ) {
      console.log("calling videocollection API** users")
      this.props.getVideoCollection(url.query.t)
    } else {
      // TODO: DISPLAY NO TEMPLATE QUERY STRING MESSAGE
    }
  }

  render() {
    const { users, campaign, isLoading } = this.props
    console.log("this.state")
    console.log(this.state)

    // console.log("users - users")
    // console.log(users)
    // console.log("users - users")
    // console.log(users)

    const campaignDetail = campaign
      ? campaign.filter(item => item.id === this.state.templateid)
      : []

    // console.log("campaignDetail - users")
    // console.log(campaignDetail)
    // let userEmailList =
    //   this.state.templateid &&
    //   users &&
    //   users[this.state.templateid] &&
    //   users[this.state.templateid].email &&
    //   users[this.state.templateid].email.length > 0 ? (
    //     users[this.state.templateid].email.map((item, i) =>
    //       item.email ? (
    //         <tr key={`users-email-${i}`}>
    //           <td>{item.email}</td>
    //         </tr>
    //       ) : null
    //     )
    //   ) : (
    //     <tr>
    //       <td>No emails yet</td>
    //     </tr>
    //   )
    let userPhoneList =
      this.state.templateid &&
      users &&
      users[this.state.templateid] &&
      users[this.state.templateid].phone &&
      users[this.state.templateid].phone.length > 0 ? (
        users[this.state.templateid].phone.map((item, i) =>
          item.phone ? (
            <tr key={`users-phone-${i}`}>
              <td>{item.phone}</td>
            </tr>
          ) : null
        )
      ) : (
        <tr>
          <td>No phone yet</td>
        </tr>
      )
    // let userBounds =
    //   this.state.templateid &&
    //   users &&
    //   users[this.state.templateid] &&
    //   users[this.state.templateid].bounds
    //     ? users[this.state.templateid].bounds
    //     : []

    // console.log("userBounds")
    // console.log(userBounds)
    // console.log(userEmailList.length)

    return (
      <div class="reporting-container">
        {isLoading ? <Loading /> : null}
        {/* {!isLoading &&
        campaignDetail &&
        campaignDetail[0] &&
        campaignDetail[0].delivery === "email" ? (
          <LabelTotal
            data={userEmailList.length}
            text="email addresses"
            singularText="email address"
          />
        ) : null} */}
        {!isLoading && campaignDetail && campaignDetail[0] ? (
          <LabelTotal
            data={userPhoneList.length}
            text="mobile numbers"
            singularText="mobile number"
          />
        ) : null}
        {/* {!isLoading && userEmailList ? (
          <table class="table table-striped table-bordered mt-2">
            <thead>
              <tr />
            </thead>
            <tbody>{userEmailList}</tbody>
          </table>
        ) : null} */}
        {!isLoading && userPhoneList ? (
          <table class="table table-striped table-bordered mt-2">
            <thead>
              <tr />
            </thead>
            <tbody>{userPhoneList}</tbody>
          </table>
        ) : null}
      </div>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
