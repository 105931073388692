import "babel-polyfill"
import "isomorphic-fetch"
import React from "react"

// import AppNav from '../components/app/appNav'
// import Amplify, { API, Auth } from "aws-amplify"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../utils/config"
import Layout from "../components/layout"
import NavApp from "../components/nav/navApp"

import ReportTitle from "../components/appReporting/reportTitle"
import ReportingCampaignOverview from "../components/appReporting/reportingCampaignOverview"
import EmailMailMerge from "../components/appActivation/emailMailMerge"
import AppCollector from "../components/appCollector/collector"
import AppTracker from "../components/appTracker/tracker"
import AppComposite from "../components/appComposite/composite"

import { Helmet } from "react-helmet"

import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"

import parse from "url-parse"

// eslint-disable-next-line

Amplify.configure(amplifyconfig)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [],
      moderationcontent: [],
      sessionhide: "",
      refreshing: false,
      loading: true,
    }
    this.videoDisplayConst = 3
  }

  render() {
    console.log("((this.state))")
    console.log(this.state)
    const url = parse(window.location.href, true)
    const pathname = url.pathname

    return (
      <Layout>
        <div class="flex-grow-1">
          {/* <AppNav logo={this.state.company && this.state.company.companylogo ? this.state.company.companylogo : ''} /> */}
          <NavApp />

          <div class="container container-app container-page container-reporting">
            {/* <div class="col-lg-3"><ReportingMenu /></div> */}
            {/* <div class="col-lg-12"> */}
            {/* </div> */}
            {pathname === "/app/home/" ? (
              <>
                {/* <div class="row">
                  <h4 class="pb-2">Merge</h4>
                </div> */}
                <div class="row">
                  <EmailMailMerge />
                </div>
              </>
            ) : null}
            {pathname === "/app/collector/" ? (
              <>
                {/* <div class="row">
                  <h4 class="pb-2">Contact Collector</h4>
                </div> */}
                <div class="row">
                  <AppCollector />
                </div>
              </>
            ) : null}
            {pathname === "/app/tracker/" ? (
              <>
                {/* <div class="row">
                  <h4 class="pb-2">Contact Collector</h4>
                </div> */}
                <div class="row">
                  <AppTracker />
                </div>
              </>
            ) : null}
            {pathname === "/app/composite/" ? (
              <>
                {/* <div class="row">
                  <h4 class="pb-2">Contact Collector</h4>
                </div> */}
                <div class="row">
                  <AppComposite />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Layout>
    )
  }
}

export default App
